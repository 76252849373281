import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { DelarImg } from "../../constant";
import Sidebar from "./Sidebar";
import "../../Assets/Css/Profile.css";
import { Link } from "react-router-dom";

const BasicInfo = () => {
	return (
		<>
			<Header />

			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>Profile</h3>
								</div>
								<div className="profile-detail">
									<div className="head">
										<div className="name-image">
											<figure>
												<img src={DelarImg} className="img-fluid" />
											</figure>
											<div className="name">
												<h4>Thomas Andrew</h4>
												<p>
													Praesent dapibus, neque id cursus faucibus, tortor
													neque egestas augue, eu vulputate magna eros eu erat.
													Aliquam erat volutpat. Nam dui mi, tincidunt quis,
													accumsan porttitor facilisis luctus metus.
												</p>
											</div>
										</div>
										<div className="edit-btn">
											<Link to="#">Edit</Link>
										</div>
									</div>
								</div>
								<div className="user-detail">
									<h4>User details</h4>
									<div className="table-responsive">
										<table className="table">
											<tr>
												<th>Name</th>
												<td>
													<input
														className="form-control"
														value="Thomas Andrew"
														readonly
														type="text"
													/>
												</td>
												<td>
													<Link to="#">Edit</Link>
												</td>
											</tr>
											<tr>
												<th>Contact Number</th>
												<td>
													<input
														className="form-control"
														value="+124 21 99 928"
														readonly
														type="tel"
													/>
												</td>
												<td>
													<Link to="#">Edit</Link>
												</td>
											</tr>
											<tr>
												<th>Password</th>
												<td>
													<input
														className="form-control"
														value="123456789"
														readonly
														type="password"
													/>
												</td>
												<td>
													<Link to="#">Edit</Link>
												</td>
											</tr>
											<tr>
												<th>E-mail</th>
												<td>
													<input
														className="form-control"
														value="Thomas@mail.com"
														readonly
														type="email"
													/>
												</td>
												<td>
													<Link to="#">Edit</Link>
												</td>
											</tr>
											<tr>
												<th>Address</th>
												<td>
													<input
														className="form-control"
														value="1148 S Railroad Ave
                            Bronxville, New York(NY), 10708"
														readonly
														type="text"
													/>
												</td>
												<td>
													<Link to="#">Edit</Link>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default BasicInfo;
