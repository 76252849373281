import React, { useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import OTPInput from "otp-input-react";

const Verification = () => {
	const [OTP, setOTP] = useState("");
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login verification">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Verification</h2>
									<p>We sent you an OTP on your email. th******ew@mail.com</p>
								</div>
								<form action="/change-pass">
									<label className="title" for="">
										Please enter code{" "}
									</label>
									<OTPInput
										value={OTP}
										onChange={setOTP}
										autoFocus
										OTPLength={4}
										otpType="number"
										disabled={false}
										secure
									/>
									<div className="button-group">
										<button type="submit">Next</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default Verification;
