import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Chat1, Chat2, Chat3, Chat4, Chat5, tabsImg } from "../../constant";
import add1 from "../../Assets/img/feature5.png";
import meter from "../../Assets/img/meter.svg";
import meter1 from "../../Assets/img/meter1.png";
import meter2 from "../../Assets/img/meter2.png";
import meter3 from "../../Assets/img/meter3.png";
import add2 from "../../Assets/img/feature6.png";
import add3 from "../../Assets/img/feature2.png";
import Sidebar from "./Sidebar";

const MyAdd = () => {
	return (
		<>
			<Header />

			<section className="profile_Sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<Sidebar />
						</div>
						<div className="col-lg-9">
							<div className="right-side">
								<div className="title">
									<h3>My Ads</h3>
									<div className="snap-chat">
										<button>Post Snapshots</button>
									</div>
								</div>
								<div className="profile-detail"></div>
							</div>
							<div className="row">
								<div className="col-lg-4 mb-3">
									<button
										type="button"
										class="btn btn-primary postAdd"
										data-bs-toggle="modal"
										data-bs-target="#postAdd"
									>
										<div className="post-add">
											<div className="inner-text">
												<h4>
													<i class="fa fa-plus" aria-hidden="true"></i> Post ad
												</h4>
												<p>Lorem Ipsum Dolor Sit Amet</p>
											</div>
										</div>
									</button>
								</div>
								<div className="col-lg-4 mb-3">
									<div className="featured-all">
										<div className="f-img">
											<img src={add1}></img>
										</div>
										<div className="feature-head">
											<h2>
												<span>$</span>21,898
											</h2>
											<div className="tag">
												<span>Go Featured</span>
												<img src={tabsImg} className="img-fluid" />
											</div>
										</div>

										<div className="feature-normal-head">
											<h3>2017 CHEVROLET VOLT LT</h3>
										</div>

										<div className="feature-icon-head">
											<span>
												<img src={meter1} />
												18,234 miles
											</span>
											<span>
												<img src={meter2} />
												267 Mi max
											</span>
										</div>
										<div className="feature-iiimmgg">
											<span>
												<img src={meter3} />
												Delivery available
											</span>
										</div>
										<div className="new-listing">
											<h5>New listing</h5>
										</div>
									</div>
								</div>
								<div className="col-lg-4 mb-3">
									<div className="featured-all">
										<div className="f-img">
											<img src={add2}></img>
										</div>
										<div className="feature-head">
											<h2>
												<span>$</span>21,898
											</h2>
											<div className="tag">
												<span>Go Featured</span>
												<img src={tabsImg} className="img-fluid" />
											</div>
										</div>

										<div className="feature-normal-head">
											<h3>2017 CHEVROLET VOLT LT</h3>
										</div>

										<div className="feature-icon-head">
											<span>
												<img src={meter1} />
												18,234 miles
											</span>
											<span>
												<img src={meter2} />
												267 Mi max
											</span>
										</div>
										<div className="feature-iiimmgg">
											<span>
												<img src={meter3} />
												Delivery available
											</span>
										</div>
										<div className="new-listing">
											<h5>New listing</h5>
										</div>
									</div>
								</div>
								<div className="col-lg-4 mb-3">
									<div className="featured-all">
										<div className="f-img">
											<img src={add3}></img>
										</div>
										<div className="feature-head">
											<h2>
												<span>$</span>21,898
											</h2>
											<div className="tag">
												<span>Go Featured</span>
												<img src={tabsImg} className="img-fluid" />
											</div>
										</div>

										<div className="feature-normal-head">
											<h3>2017 CHEVROLET VOLT LT</h3>
										</div>

										<div className="feature-icon-head">
											<span>
												<img src={meter1} />
												18,234 miles
											</span>
											<span>
												<img src={meter2} />
												267 Mi max
											</span>
										</div>
										<div className="feature-iiimmgg">
											<span>
												<img src={meter3} />
												Delivery available
											</span>
										</div>
										<div className="new-listing">
											<h5>New listing</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="post-add-modal">
				<div
					class="modal fade"
					id="postAdd"
					tabindex="-1"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">
									<img src={tabsImg} className="img-fluid" alt="" />
									<span>Go Feature Sale Faster</span>
									<img src={tabsImg} className="img-fluid" alt="" />
								</h5>
							</div>
							<div class="modal-body">
								<ul>
									<li>
										<span>
											<label class="price_container">
												<input
													type="radio"
													hidden
													checked="checked"
													name="Addradio"
												/>
												<span class="checkmark">
													<span className="dot"></span>
													<ruby>Go Feature 5 Days</ruby>
												</span>
											</label>
										</span>
										<span className="price">$20.00</span>
									</li>
									<li>
										<span>
											<label class="price_container">
												<input type="radio" hidden name="Addradio" />
												<span class="checkmark">
													<span className="dot"></span>
													<ruby>Go Feature 10 Days</ruby>
												</span>
											</label>
										</span>
										<span className="price">$30.00</span>
									</li>
									<li>
										<span>
											<label class="price_container">
												<input type="radio" hidden name="Addradio" />
												<span class="checkmark">
													<span className="dot"></span>
													<ruby>Go Feature 15 Days</ruby>
												</span>
											</label>
										</span>
										<span className="price">$40.00</span>
									</li>
								</ul>
								<div className="button-group">
									<button className="btn">Go Featured</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default MyAdd;
