import React from "react";
import Slider from "react-slick";
import banner from "../../Assets/img/banner.png";
import searchIcon from "../../Assets/img/searchIcon.svg";

function SampleNextArrow(props) {
	const { style, onClick } = props;
	return (
		<div
			// className='Slider_prod_custom_arrows-next'
			style={{
				...style,
				display: "block",
				background: "red",
				height: "180px",
				width: "180px",
			}}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { style, onClick } = props;
	return (
		<div
			// className="Slider_top_custom_arrows"
			style={{
				...style,
				display: "block",
				background: "green",
				height: "180px",
				width: "180px",
			}}
			onClick={onClick}
		/>
	);
}

const settings = {
	arrows: true,
	dots: true,
	infinite: true,
	fade: true,
	// speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 3000,
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
};

const TopSlider = () => {
	return (
		<>
			<Slider>
				<div className="slider">
					<img src={banner}></img>

					<div className="slider-all">
						<div className="row px-5">
							<div className="col-md-3"></div>
							<div className="col-md-6">
								<div className="searchBox wow animate__animated animate__bounce">
									<div className="slider-input ">
										<input
											type="text"
											placeholder="Plug in your search"
										></input>
									</div>
									<div className="search-icon">
										{/* <i class="fa fa-search" aria-hidden="true"></i> */}
										<img src={searchIcon} alt="searchIcon" />
									</div>
								</div>
							</div>
							<div className="col-md-3"></div>
						</div>

						{/* <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div> */}
					</div>
				</div>

				{/* <div className="slider">
            <img src={banner}></img>
            <div className="slider-all">
                     
                <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div>

               </div>
            </div> */}
				{/* <div className="slider">
            <img src={banner}></img>

            <div className="slider-all">
                     
                <div className="slide-text"> 
                <h5>My Name Is Shane Phelps</h5>

                <h2>Welcome to a</h2>
                <h2> <span>SixFortyThree</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard <br /> dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is <br /> simply dummy text of the printing and typesetting industry</p>
                <div className="slide-btn">
                    <a href="">Learn more</a>
                </div>

                </div>

               </div>
            </div> */}
			</Slider>
		</>
	);
};

export default TopSlider;
