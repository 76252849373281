import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/img/logo.png";
import active from "../Assets/img/active.png";
import login from "../Assets/img/login.png";
import { useLocation } from "react-router-dom";

const Header = () => {
	const location = useLocation();
	console.log("header ", location.pathname);
	return (
		<>
			<div className="Header">
				<div className="container">
					<div className="row">
						<div className="col-md-5">
							<div className="header-logo">
								<Link to="/">
									<img className="img-fluid" src={logo} />
								</Link>
							</div>
						</div>
						<div className="col-md-7">
							<div className="header-list">
								<ul>
									<></>
									{location.pathname == "/" ? (
										<>
											<li>
												<Link to="">buy</Link>
												<span>
													<img src={active} />
												</span>
												<ul className="dropdown-inner">
													<li>
														<Link to="#">Cars</Link>
													</li>
													<li>
														<Link to="#">Trucks</Link>
													</li>
													<li>
														<Link to="#">Motocycles</Link>
													</li>
													<li>
														<Link to="#">Other</Link>
													</li>
												</ul>
											</li>
										</>
									) : (
										<li>
											<Link to="">buy</Link>
											<ul className="dropdown-inner">
												<li>
													<Link to="#">Cars</Link>
												</li>
												<li>
													<Link to="#">Trucks</Link>
												</li>
												<li>
													<Link to="#">Motocycles</Link>
												</li>
												<li>
													<Link to="#">Other</Link>
												</li>
											</ul>
										</li>
									)}
									<li>
										<Link to="/sell">sell</Link>
										<ul className="dropdown-inner">
											<li>
												<Link to="#">Private Sale</Link>
											</li>
											<li>
												<Link to="/dealer">Dealer Sale</Link>
											</li>
										</ul>
									</li>
									<li>
										<Link to="#">Learn</Link>
										<ul className="dropdown-inner">
											<li>
												<Link to="/blog">Blog</Link>
											</li>
											<li>
												<Link to="/faqs">FAQ</Link>
											</li>
											<li>
												<Link to="#">News</Link>
											</li>
											<li>
												<Link to="/Blog-details">Research</Link>
											</li>
										</ul>
									</li>
									<li>
										<Link to="/About">about</Link>
									</li>
									<li>
										<Link to="/all-dealer">dealers</Link>
									</li>
									<li>
										<Link className="login-btn" to="/login">
											My Account
										</Link>
									</li>
								</ul>
							</div>

							<div className="login-btn"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="actionLink">
				<div className="button-group">
					<div className="box">
						<div className="contactDv">
							<div className="contact-btn">Contact us</div>
							<div className="chatDv">
								<h3>Chat with Us</h3>
								<form>
									<div className="form-group">
										<input
											className="form-control"
											type="text"
											placeholder="Your Name"
										/>
									</div>
									<div className="form-group">
										<input
											className="form-control"
											type="email"
											placeholder="Email"
										/>
									</div>
									<div className="form-group">
										<input
											className="form-control"
											type="text"
											placeholder="Contact"
										/>
									</div>
									<div className="form-group">
										<textarea
											className="form-control"
											placeholder="Your Message"
										></textarea>
									</div>
									<button type="submit">Submit</button>
								</form>
							</div>
						</div>
					</div>
					<div className="contactDv newsletter">
						<div className="contact-btn">Subscribe</div>
						<div className="chatDv">
							<h3>Subscribe for Newsletter</h3>
							<form>
								<div className="form-group">
									<input
										className="form-control"
										type="email"
										placeholder="Email"
									/>
								</div>
								<button type="submit">Subscribe</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
