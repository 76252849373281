import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/login.css";
import { Link } from "react-router-dom";

const Login = () => {
	return (
		<>
			<Header />

			{/* Login Sec Start here */}
			<section className="login">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="login_box">
								<div className="heading">
									<h2>Login</h2>
									<p>
										Does'nt have an account ?{" "}
										<Link to="/register">Sign Up</Link>
									</p>
								</div>
								<form action="/profile">
									<div className="form-group">
										<input
											className="form-control"
											type="email"
											placeholder="Email Address"
										/>
									</div>
									<div className="form-group">
										<input
											className="form-control"
											type="password"
											placeholder="Password"
										/>
									</div>
									<div className="rememberDv">
										<div className="form-group">
											<input
												className="form-check-input"
												id="checkk"
												type="checkbox"
											/>
											<label for="checkk">Remember me</label>
										</div>
										<div className="forgot">
											<Link to="/enter-email">Forget Password?</Link>
										</div>
									</div>
									<div className="button-group">
										<button type="submit">Login</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
				</div>
			</section>
			{/* Login Sec End here */}

			<Footer />
		</>
	);
};

export default Login;
