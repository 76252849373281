import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import "../../Assets/Css/about.css";
import { dots_img, mission_img, mission_line } from "../../constant";
import ReCAPTCHA from "react-google-recaptcha";

const About = () => {
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  return (
    <>
      <Header />
      {/* About banner starts here */}
      <section className="about-banner">
        <div className="conteiner">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-banner-heading">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About banner ends here */}
      {/* about content sec starts here */}
      <section className="about-content-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about content sec ends here */}
      {/* vision sec starts here */}
      <section className="vision-sec">
        <div className="container">
          <div className="col-lg-7">
            <div className="vision-card-wrapper">
              <h2>Our Vision</h2>
              <p>
                Duis Aute Irure Dolor In Reprehenderit In Voluptate Velit Esse
                Cillum Dolore Eu Fugiat Null Duis Aute Irure Dolor In
                Reprehenderit In Oluptate Duis Aute Irure Dolor In Reprehenderit
                In Voluptate Velit Esse Cillum Dolore Eu Fugiat Null Duis Aute
                Irure Dolor In Reprehenderit In Oluptate{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </section>
      {/* vision sec ends here */}
      {/* mission starts here */}
      <section className="mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img-wrappper">
                <figure>
                  <img src={mission_img} className="img-fluid" />
                  <img src={dots_img} className="dots" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="content-wrapper">
                <h2>Our Mission</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat null Duis aute irure dolor in
                  reprehenderit in oluptate Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  null Duis aute irure dolor in reprehenderit in oluptate
                </p>
                <button className="btn">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-line">
          <figure>
            <img src={mission_line} />
          </figure>
        </div>
      </section>
      {/* mission ends here */}
      {/* contact us starts here */}
      <section className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="contact-form-wrapper">
                <div className="contact-heading-wrapper">
                  <h2>CONTACT US</h2>
                </div>
                <div className="form-wrapper">
                  <form>
                    <div className="form-group">
                      <select className="form-control">
                        <option value="I Want To Buy" disabled selected>
                          I Want To Buy
                        </option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="5"
                        cols="10"
                        placeholder="How We Can We Help You"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey="6LeCaCohAAAAAKRZFr_EaPQdGBsGuCCIhYTn2uwe"
                        onChange={onChange}
                      />
                      ,
                    </div>
                    <div className="form-group">
                      <button className="btn form-control">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </section>
      {/* contact us ends here */}
      <Footer />
    </>
  );
};

export default About;
