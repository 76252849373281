import React, { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "../../Assets/Css/PostVehicleAds.css";
import { FileUploader } from "react-drag-drop-files";

const PostVehicleAds = () => {
	const fileTypes = ["JPG", "PNG", "GIF"];
	const [file, setFile] = useState(null);
	const handleChange = (file) => {
		setFile(file);
	};
	let [radiocheck, setradiocheck] = useState(false);
	let handlecheck = () => {
		setradiocheck(true);
	};
	let handlecheckfalse = () => {
		setradiocheck(false);
	};
	return (
		<>
			<Header />
			{/* PostVehicle starts here */}
			<section className="PostVehicle">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="content-wrapper">
								<h4>Post Vehicle Ads</h4>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<form action="">
								<div className="form-group row">
									{/* <div className="col-lg-6">
										<input
											type="text"
											placeholder="Dealership"
											className="form-control"
										/>
									</div> */}
									<div className="col-lg-12">
										<input
											type="text"
											placeholder="Location"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Mileage"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Year"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Make"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Model"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Trim"
											className="form-control"
										/>
									</div>
									<div className="col-lg-6">
										<input
											type="text"
											placeholder="Photos"
											className="form-control"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-12">
										<div className="form-heading-wrapper">
											<h5>
												Share your best stories and experiences with this EV:
											</h5>
										</div>
									</div>
									<div className="col-lg-12">
										<textarea
											name=""
											className="form-control"
											id=""
											cols="30"
											rows="5"
											placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
										></textarea>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Required images:</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Font ¾ left</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor=""> Rear ¾ left</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Rear on</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor=""> Through passenger door</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Head on</label>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Additional Images (Optional)</h5>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Through Passenger Door</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Head On</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Front And Rear Seats</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor=""> Gauges</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Detail Shots Grille And Emblem</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Grille And Emblem</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Headlight</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Shifter</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Side View Mirror</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Rear Apron</label>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="file-wrapper">
											<FileUploader
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												label=""
											/>
											<label htmlFor="">Wheels</label>
										</div>
									</div>
								</div>
								<div className="form-group">
									<div className="form-heading-wrapper">
										<h5>Sale Options</h5>
									</div>
								</div>
								<div className="form-group  d-flex">
									<div onClick={handlecheckfalse} class="form-check mr-2">
										<input
											class="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios1"
											value="option1"
										/>
										<label class="form-check-label" for="exampleRadios1">
											For Normal Sell
										</label>
									</div>
									<div onClick={handlecheck} class="form-check ml-2">
										<input
											class="form-check-input"
											type="radio"
											name="exampleRadios"
											id="exampleRadios2"
											value="option2"
										/>
										<label class="form-check-label" for="exampleRadios2">
											For Auction
										</label>
									</div>
								</div>
								<div className="form-group row pt-4">
									<div className="col-lg-6">
										<input
											type="text"
											className="form-control"
											placeholder="Price"
										/>
									</div>
									<div className="col-lg-6">
										{radiocheck ? (
											<input
												type="text"
												className="form-control"
												placeholder="Due date for bidding"
											/>
										) : null}
									</div>
								</div>
								<div className="form-group mt-3">
									<button className="btn">Submit</button>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* PostVehicle ends here */}
			<Footer />
		</>
	);
};

export default PostVehicleAds;
