import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
	cartimg,
	detail_img,
	meter_img,
	meter1,
	meter2,
	meter3,
	feature1,
	feature2,
	feature3,
	feature4,
	feature5,
	feature6,
	meter,
	buyer1,
	buyer2,
	buyer3,
} from "../../constant/index";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import "../../Assets/Css/car-detail.css";
const cardetailpage = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	let equiplist = [
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
		{
			list_no: "Am/Fm Stereo",
		},
		{
			list_no: "Hd Radio",
		},
		{
			list_no: " Trip Computer",
		},
		{
			list_no: " Floor Mats",
		},
		{
			list_no: "Keyless Entry",
		},
		{
			list_no: "Turbocharged",
		},
	];
	return (
		<>
			<Header />
			{/* car  detal sec starts here */}
			<section className="cardetail-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="img-and-btn-wrapper">
								<div className="slider-wrapper">
									<Slider {...settings}>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">01</span>
											</figure>
										</div>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">02</span>
											</figure>
										</div>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">03</span>
											</figure>
										</div>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">04</span>
											</figure>
										</div>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">05</span>
											</figure>
										</div>
										<div className="detail-img-wrapper">
											<figure>
												<img src={detail_img} className="img-fluid" />
												<span className="no">06</span>
											</figure>
										</div>
									</Slider>
								</div>
								<div className="btn-wrapper">
									<a href="/carView">
										<img src={cartimg} />
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="detail-content-wrapper">
								<div className="top-btn-wrapper">
									<h4>
										ASKING PRICE: <span className="price">$31,004</span>
									</h4>
									<button className="btn">Message Seller</button>
									<button className="btnBlack">Request CAR FAX</button>
									<button className="btngrey">Battery Health Report</button>
								</div>
								<div className="title-name-wrapper">
									<h2>MINI S E COUNTRYMAN ALL 4 CLASSIC</h2>
									<h5>
										{" "}
										<i className="fa fa-map-marker"></i> Miami, FL
									</h5>
								</div>
								<div className="stats-wrapper">
									<h5>
										39,194
										<span className="statsname">Mileage:</span>
									</h5>
									<h5>
										270 Mi
										<span className="statsname">Total Range:</span>
									</h5>
									<h5>
										5<span className="statsname">Seats:</span>
									</h5>
								</div>
								<div className="highlights-wrapper">
									<h4>HIGHLIGHTS:</h4>
									<div className="highlights-list-wrapper">
										<ul>
											<li>
												{" "}
												<i className="fa fa-check"></i> Park Distance Control
											</li>
											<li>
												{" "}
												<i className="fa fa-check"></i> Comfort and Convenience
												Package
											</li>
											<li>
												{" "}
												<i className="fa fa-check"></i> Panoramic Sunroof
											</li>
											<li>
												{" "}
												<i className="fa fa-check"></i> Back Up Camera
											</li>
											<li>
												<i className="fa fa-check"></i> Park Distance Control
											</li>
											<li>
												<i className="fa fa-check"></i> AWD
											</li>
										</ul>
									</div>
								</div>
								<div className="seller-snap-wrapper">
									<div className="seller-heading-wrapper">
										<h5>Seller Snapshot :</h5>
									</div>
									<div className="seller-des-wrapper">
										<p>
											Active Driving Assistant, Advanced Real-Time Traffic
											Information, Alarm System, Apple CarPlay Compatibility,
											Brake assist, Comfort Access Keyless Entry, Electronic
											Stability Control, Exterior Parking Camera Rear, Four
											wheel independent suspension, harman/kardon® Logic 7®
											Digital Surround, MINI Driving Modes, MINI Yours Leather
											Steering Wheel, Panoramic Moonroof, Piano Black Interior
											Surface, Power moonroof.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* car  detal sec ends here */}
			{/* car sspecification starts here */}
			<section className="car-specification">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="specs-wrapper">
								<h6>EXTERIOR:</h6>
								<ul>
									<li>
										<span className="property">Body Style</span>
										<span className="value">Hatchback</span>
									</li>
									<li>
										<span className="property">Exterior Color</span>
										<span className="value">Blue</span>
									</li>
									<li>
										<span className="property">Length</span>
										<span className="value">169.8"</span>
									</li>
									<li>
										<span className="property">Wheelbase</span>
										<span className="value">105.1"</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="specs-wrapper">
								<h6>INTERIOR:</h6>
								<ul>
									<li>
										<span className="property">Seating</span>
										<span className="value">5</span>
									</li>
									<li>
										<span className="property">Interior Color</span>
										<span className="value">N/A</span>
									</li>
									<li>
										<span className="property">Head Room Front</span>
										<span className="value">39.9"</span>
									</li>
									<li>
										<span className="property">Head Room Rear</span>
										<span className="value">36.3"</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="specs-wrapper">
								<h6>PERFORMANCE:</h6>
								<ul>
									<li>
										<span className="property">Total Range</span>
										<span className="value">Hatchback</span>
									</li>
									<li>
										<span className="property">Electric Range</span>
										<span className="value">Blue</span>
									</li>
									<li>
										<span className="property">EPA/Combined MPG</span>
										<span className="value">169.8"</span>
									</li>
									<li>
										<span className="property">0-60</span>
										<span className="value">105.1"</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* car sspecification ends here */}
			{/* equipments sec starts here */}
			<section className="EQUIPMENT-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="equipment-wrapper">
								<div className="equipment-heading-wrapper">
									<h5>EQUIPMENT & OPTIONS:</h5>
								</div>
								<div className="equip-list-wrapper">
									<ul>
										{equiplist.map((data, index) => {
											return (
												<li key={index}>
													<img src={meter_img} /> {data.list_no}
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* equipments sec ends here */}
			{/* Buyer's Tools starts here */}
			<section className="BuyerTools">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 pb-4">
							<div className="content-wrapper">
								<h4>Buyer's Tools</h4>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="buyer-card-wrapper">
								<div className="buyer-img-wrapper">
									<figure>
										<img src={buyer1} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="buyer-content-wrapper">
									<h5>Customize Your Ride</h5>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="buyer-card-wrapper">
								<div className="buyer-img-wrapper">
									<figure>
										<img src={buyer2} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="buyer-content-wrapper">
									<h5>Shipping Quotes</h5>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="buyer-card-wrapper">
								<div className="buyer-img-wrapper">
									<figure>
										<img src={buyer3} className="img-fluid" alt="" />
									</figure>
								</div>
								<div className="buyer-content-wrapper">
									<h5>Financing Options</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Buyer's Tools ends here */}
			{/* Featured section  */}
			<section className="feature-sec  ">
				<div className="container">
					<div className="car-head">
						<h3>SIMILAR ELECTRIC VEHICLES FOR SALE:</h3>
					</div>
					<div className="row wow animate__animated animate__slideInLeft">
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature1}></img>
									<div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> No accident
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Delivery available
									</span>
								</div>

								<div className="checkup">
									<button className="btn ">View more</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature4}></img>
									<div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> No accident
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Delivery available
									</span>
								</div>

								<div className="checkup">
									<button className="btn ">View more</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature5}></img>
									<div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> No accident
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Delivery available
									</span>
								</div>

								<div className="checkup">
									<button className="btn ">View more</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="featured-all">
								<div className="f-img">
									<img src={feature6}></img>
									<div className="actions">
										<a href="#">
											<i class="fa fa-heart" aria-hidden="true"></i>
										</a>
										<a href="#">
											<i class="fa fa-eye-slash" aria-hidden="true"></i>
										</a>
									</div>
								</div>
								<div className="feature-head">
									<h2>
										<span>$</span>21,898
									</h2>
									{/* <p>
										<i class="fa fa-car" aria-hidden="true"></i> For price |
										$566 below market
									</p> */}
								</div>

								<div className="feature-normal-head">
									<h3>2017 CHEVROLET VOLT LT</h3>
								</div>

								<div className="feature-icon-head">
									<span>
										<img src={meter1}></img> 18,234 miles
									</span>
									<span>
										<img src={meter2}></img> No accident
									</span>
								</div>
								<div className="feature-iiimmgg">
									<span>
										<img src={meter3}></img> Delivery available
									</span>
								</div>

								<div className="checkup">
									<button className="btn">View more</button>
								</div>

								<div className="new-listing">
									<h5>New listing</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Featured section  */}
			<Footer />
		</>
	);
};

export default cardetailpage;
